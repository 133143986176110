@import '../theme';
@import 'bootstrap/scss/bootstrap';

.pub {
  position: relative;
  height: 100%;

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-bottom: 2.4rem;
    overflow: auto;
  }

  &__footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: map-get($spacers, 3);
    text-align: center;
    background: $white;
    font-size: .9rem;

    & a {
      color: $text-muted !important;
    }
  }
}