@import 'bootstrap/scss/bootstrap';

.loader {

  .modal-content {
    width: 120px !important;
    height: 120px !important;
    left: calc(50% - 60px);
    top: calc(50% - 60px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__spinner {
    width: 2.5rem !important;
    height: 2.5rem !important;
  }

  &__text {
    margin-top: map-get($spacers, 2);
  }
}