@import '../theme';
@import 'bootstrap/scss/bootstrap';

$box-height: 10rem;
$margin-bottom: map-get($spacers, 3);

.box {
  height: $box-height;
  margin-bottom: $margin-bottom;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius;
  border-width: $card-border-width;
  border-color: $card-border-color;
  border-style: solid;
  transition: box-shadow 300ms;

  &:hover {
    cursor: pointer;
    text-decoration: none;
    box-shadow: $box-shadow;
  }

  &__title {
    color: $primary;
    font-weight: bold;
  }

  &__number {
    font-size: $h1-font-size;
    color: $primary;
  }
}

.quickadd {
  margin-bottom: $margin-bottom;
  height: $box-height;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius;
  text-align: center;
  color: $gray-600;
  background: $gray-100;
  transition: background 500ms, color 500ms;

  &:hover {
    background: $gray-200;
    color: $gray-700;
    cursor: pointer;
  }
}