@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;0,800;1,400;1,700;1,800&display=swap');

$teal-blue: #4d727e;
$cultured: #f4f7f7;
$cadet-blue: #79a8a9;
$midnight-green: #1f4e5f;
$gun-metal: #2c363f;

$font-family: 'Poppins', sans-serif;

:export {
  primary: $teal-blue;
  fontFamily: $font-family;
}