@import './app.module';

$primary: $teal-blue;
$success: $teal-blue;
$danger: #e05e55;
$dark: $gun-metal;

$headings-color: $primary;

$font-family-sans-serif: $font-family;

$border-radius: .5rem;

$input-btn-padding-x: .9rem;
$input-btn-padding-y: .6rem;