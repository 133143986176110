@import './theme';
@import 'bootstrap/scss/bootstrap';
@import 'animate.css/animate.css';
@import 'react-toastify/scss/main.scss';

html, body, #root {
  height: 100%;
}

.firebase-emulator-warning {
  display: none;
}

.p-toast {
  .Toastify__toast {
    border-radius: $border-radius;
    font-family: 'Poppins', sans-serif;
    padding: 0;
  }

  .Toastify__toast-body {
    margin: 0;
    padding: 0;
    width: 100%;
    background: $primary;
  }

  @media only screen and (max-width: 480px) {

    .Toastify__toast-container {
      padding: 1rem;
      margin: 2rem;
    }
    
  }
}